<template>
  <div
    v-if="data"
    class="areal-table"
    :style="`--areal-table-columns: .5fr repeat(${properties.length + 1}, 1fr)`"
  >
    <div class="areal-table__row areal-table__header">
      <div class="areal-table__cell">Suite</div>
      <div class="areal-table__cell" data-align="end" v-for="prop in properties">
        {{ $t(prop) }}
      </div>
      <div class="areal-table__cell" data-align="end">PDF plantegning</div>
    </div>
    <div class="areal-table__row" v-for="(areal, i) in data.areals">
      <div class="areal-table__cell">
        {{ areal.displayName }}
      </div>
      <div class="areal-table__cell" data-align="end" v-for="prop in areal.featuredProperties">
        {{ getProperty(areal, prop) }}
      </div>
      <a
        class="areal-table__cell"
        data-align="end"
        :href="$path('media.floorplan.0.url', areal)"
        target="_blank"
      >
        Åpne plantegning
      </a>
    </div>
    <div class="areal-table__row areal-table__total">
      <div class="areal-table__cell">
        {{ $t('total') }}
      </div>
      <div class="areal-table__cell" data-align="end" v-for="prop in properties">
        {{ getTotalArea(prop) }}
      </div>
      <div class="areal-table__cell"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      properties: [],
    }
  },
  computed: {
    ...mapState('Project', ['promises']),
  },
  methods: {
    getProperties() {
      const areals = this.$path('data.areals')
      if (!areals) return

      const p = areals.map(areal => areal.featuredProperties).flat()

      this.properties = [...new Set(p)]
    },
    getProperty(item, prop) {
      const value = item.properties.find(a => a.key == prop)
      if (!value) return
      return value.formatted
    },
    getTotalArea(property) {
      const areals = this.$path('data.areals')
      if (!areals) return

      const total = areals.reduce((acc, areal) => {
        const prop = areal.properties.find(a => a.key == property)
        if (!prop) return acc

        const value = Number(prop.formatted.replace(/[^0-9.-]+/g, ''))
        return acc + value
      }, 0)

      return `${total} m²`
    },
  },
  mounted() {
    this.promises.fetch.then(() => {
      this.getProperties()
    })
  },
}
</script>

<style lang="scss">
.areal-table {
  max-width: 900px;
  width: 100%;
  margin-inline: auto;
  overflow-x: auto;

  display: grid;
  grid-template-columns: var(--areal-table-columns);

  &__row {
    display: contents;
    white-space: nowrap;

    &:not(.areal-table__header) {
      .areal-table__cell {
        border-bottom: 1px solid var(--secondary);
      }
    }
  }

  &__cell {
    padding: 0.75rem 2rem;

    &[data-align='end'] {
      text-align: end;
    }
  }

  &__header {
    font-weight: bold;

    .areal-table__cell {
      background-color: var(--secondary);
    }
  }
}
</style>
