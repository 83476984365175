<template>
  <div class="property-maps">
    <div class="property-maps__buttons">
      <ButtonComponent
        v-for="(map, index) in data.items"
        :label="map.title"
        @click="setSelectedIndex(index)"
        class="property-maps__button"
        :class="{ 'property-maps__button--active': selectedIndex === index }"
      />
    </div>
    <Embed :data="item" />
  </div>
</template>

<script>
import { Components } from '@kvass/pagebuilder'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  computed: {
    item() {
      const items = this.$path('data.items')
      if (!items) return { src: '' }

      const i = items[this.selectedIndex]
      return { src: i.url }
    },
  },
  methods: {
    setSelectedIndex(index) {
      this.selectedIndex = index
    },
  },
  components: {
    Embed: Components.Embed,
  },
}
</script>

<style lang="scss">
.property-maps {
  &__buttons {
    margin-block: 6rem 2rem;
    max-width: 900px;
    margin-inline: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__button {
    &--active {
      border-color: var(--primary);
    }
  }
}
</style>
