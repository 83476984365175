<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :items="items"
          padding="1.3rem 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
          :logo="{ src: logo, height: 48 }"
          :break-at="900"
        />
      </div>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM : {}
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        {
          label: 'Om eiendommen',
          action: '#section-om-eiendommen'
        },
        {
          label: 'Fasiliteter',
          action: '#section-fasiliteter',
        },
        {
          label: 'Beliggenhet',
          action: '#section-beliggenhet',
        },
        {
          label: 'Ledige lokaler',
          action: '#section-ledige-lokaler',
        },
        {
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          action: '#section-kontakt',
          icon: { icon: 'arrow-right', size: 'xs' },
        },
      ]
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || forceRedirect)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          console.log(target)
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: $background;

  &--project-expanded {
    position: fixed;
    height: 100%;
  }
}
</style>
