<template>
  <Loader :value="promises.fetch" theme="default" class="project-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { Blocks } from '@kvass/pagebuilder'
import ScrollAnchor from '@kvass/scroll-anchor'
import Masonry from '@/components/Masonry'
import PropertyMaps from '@/components/PropertyMaps'
import ArealTable from '@/components/ArealTable'
import Lead from '@/components/Lead'

import Hero from '@/components/Hero'

import config from '@/config'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { Masonry, Hero, ScrollAnchor, PropertyMaps, ArealTable, Lead }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  /* Pagebuilder styles */
  .kpb-text {
    max-width: 900px;
    margin-inline: auto;
  }
  .kpb-section--theme-secondary {
    background-color: var(--secondary);
    box-shadow: inset 0 0 2rem rgba(black, 0.1);
  }
  .kpb-contact-list {
    &__phone {
      margin-top: 1rem;
    }

    &__company-logo {
      margin-top: 2rem;
      max-height: 4rem;
      max-width: 10rem;
      height: auto;

      @include respond-below('phone') {
        display: none;
      }
    }

    &__name {
      font-family: var(--primary-font);
      margin-bottom: 0;
    }

    &__avatar {
      @include respond-below('phone') {
        max-width: 30%;
        height: auto;
        object-fit: contain;
        object-position: top;
      }
    }
  }
  .kpb-section {
    &.no-padding {
      padding: 0;
    }
  }

  /* Sections */
  .section {
    &-hero {
      padding-block: 0;
    }
    &-owner {
      .kpb-image__image {
        background-color: transparent;
      }
    }
  }
}
</style>
