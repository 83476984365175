export default function () {
  return [
    // hero and intro
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-hero'
      },
      blocks: {
        default: {
          component: 'Hero',
          props: {
            images: this.$path('item.media.cover'),
          },
        },
      }
    },
    {
      component: 'Section',
      props: {
        width: 'large',
        style: 'padding-top: 4rem'
      },
      blocks: {
        default: {
          component: 'Text',
          data: {
            content: this.$path('item.description'),
          }
        }
      }
    },

    // top-images
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-top-gallery no-padding'
      },
      blocks: {
        default: [
          ...(this.$path('item.customFields.top-gallery') || []).map(images => {
            return {
              component: 'Masonry',
              data: { value: images, }
            }
          })
        ]
      }
    },

    // about
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-about',
        id: 'om-eiendommen'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.about-content'),
            }
          },
          ...(this.$path('item.customFields.about-images') || []).map(images => {
            return {
              component: 'Masonry',
              props: {
                style: 'margin-block: 6rem',
              },
              data: { value: images }
            }
          }),
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.about-more-content'),
            }
          },
          ...(this.$path('item.customFields.about-more-images') || [])
            .filter(Boolean)
            .map(images => {
              return {
                component: 'Image',
                props: {
                  // style: 'margin-block: 6rem'
                },
                data: { image: images[0] }
              }
            }),
        ]
      }
    },

    // facilities
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-facilities',
        id: 'fasiliteter'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'margin-bottom: 4rem'
            },
            data: {
              content: this.$path('item.customFields.facilities-content'),
            }
          },
          ...(this.$path('item.customFields.facilities-images') || []).map(images => {
            return {
              component: 'Masonry',
              data: { value: images }
            }
          }),
        ]
      }
    },

    // location
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-location kpb-section--theme-secondary',
        id: 'beliggenhet'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'margin-bottom: 4rem'
            },
            data: {
              content: this.$path('item.customFields.location-content'),
            }
          },
          ...(this.$path('item.customFields.location-images') || []).map(images => {
            return {
              component: 'Masonry',
              data: { value: images }
            }
          }),
          {
            component: 'Text',
            props: {
              style: 'margin-block: 4rem'
            },
            data: {
              content: this.$path('item.customFields.location-more-content'),
            }
          },
          ...(this.$path('item.customFields.location-more-images') || []).map(images => {
            return {
              component: 'Masonry',
              data: { value: images }
            }
          }),
        ]
      }
    },

    // properties
    {
      component: 'Section',
      condition: () => (this.$path('item.customFields.properties-maps') || []).filter(p => p.title && p.url).length,
      props: {
        width: 'large',
        id: 'ledige-lokaler'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.$path('item.customFields.properties-content'),
            }
          },
          {
            component: 'PropertyMaps',
            data: {
              items: this.$path('item.customFields.properties-maps'),
            }
          }
        ]
      }
    },

    // sustainability
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'no-padding'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'margin-bottom: 4rem'
            },
            data: {
              content: this.$path('item.customFields.sustainability-content'),
            }
          },
          ...(this.$path('item.customFields.sustainability-images') || []).map(images => {
            return {
              component: 'Masonry',
              data: { value: images }
            }
          }),
        ]
      }
    },

    // plans
    {
      component: 'Section',
      props: {
        width: 'large',
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            flexDirection: 'column',
          },
          blocks: {
            default: [
              {
                component: 'Text',
                props: {
                  style: 'width: 100%;'
                },
                data: {
                  content: this.$path('item.customFields.table-content'),
                }
              },
              {
                component: 'ArealTable',
                props: {
                  style: 'margin-bottom: 4rem'
                },
                data: {
                  areals: this.$path('item.residentials'),
                }
              },
              ...(this.$path('item.residentials') || []).map(residential => {
                return {
                  component: 'Image',
                  data: {
                    image: residential.media.cover[0],
                  }
                }
              })
            ]
          }
        }
      }
    },

    // owner
    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'kpb-section--theme-secondary section-owner',
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            flexDirection: 'column',
            alignItems: 'center',
          },
          blocks: {
            default: [
              {
                component: 'Image',
                data: {
                  image: this.$path('item.customFields.owner.logo'),
                }
              },
              {
                component: 'Text',
                data: {
                  content: this.$path('item.customFields.owner.content'),
                }
              }
            ]
          }
        }
      }
    },

    // contact
    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'kontakt'
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            alignItems: 'start'
          },
          blocks: {
            default: [
              {
                component: 'ContactList',
                variant: 'horizontal',
                props: { gap: '4rem', contactDetailStyle: 'icon', showEmailLabel: true },
                data: {
                  contacts: this.$path('item.roles.salesman'),
                }
              },
              {
                component: 'Lead',
                props: {
                  title: this.$path('item.customFields.lead-title') || '<h2>MELD INTERESSE</h2>',
                }
              }
            ]
          }
        }
      }
    }
  ]
}
