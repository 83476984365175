<template>
  <Lead
    class="project-lead"
    :project="item.id"
    :reference="{ onModel: 'Project', ref: item.id }"
    :fields="['!contact.upsell', '!comment']"
  >
    <template #title>
      <div v-html="title"></div>
    </template>
  </Lead>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  props: {
    title: String,
  },
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 650px;
  margin: 0 auto;
  background-color: transparent !important;

  .elder-input {
    &__label {
      text-transform: uppercase;
    }

    &__field {
      border-color: var(--secondary);

      &--focus {
        border-bottom: initial;
      }
    }

    &__element {
      padding: 0.6rem 0.8rem;
    }
  }

  @include respond-below('phone') {
    padding: 1rem !important;
  }

  .lead__form {
    background-color: transparent;

    &-actions {
      justify-content: start;
    }
  }
}
</style>
