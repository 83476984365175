<template>
  <Masonry
    v-if="data.value"
    :value="data.value"
    caption-visibility="visible"
    class="post-masonry"
  />
</template>

<script>
import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  props: {
    data: Object,
  },
  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.post-masonry {
  padding-inline: 0 !important;

  @include respond-below('phone') {
    padding-block: 0.25rem !important;
  }

  .masonry {
    &__content {
      gap: 1rem;

      &.masonry__content--length-1 {
        grid-template-columns: 1fr;
      }

      &.masonry__content--length-3 {
        grid-template-rows: repeat(2, 1fr);

        .masonry__item:first-child {
          grid-row-end: span 2;
        }
      }

      @include respond-above('tablet') {
        &:not(.masonry__content--length-1) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @include respond-below('phone') {
        gap: 0.5rem;
      }
    }
  }
}
</style>
