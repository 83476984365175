<template>
  <footer class="footer">
    <div class="footer__content">
      <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content container"
      />
      <div class="footer__container container">
        <img class="footer__logo" :src="logo" width="200" :alt="item.name" />
        <div>
          <div class="footer__copyright">{{ year }} &copy; {{ item.name }}</div>
          <div class="footer__links">
            <a href="/api/legal/privacy/tenant" target="_blank">
              {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
            </a>
            <router-link :to="{ name: 'Disclaimer' }">Forbehold</router-link>
            <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          </div>
        </div>
        <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
        <div class="footer__menu">
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
              icon=""
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="row between wrap container" style="gap: 2rem">
        <span v-html="$path('customFields.footer-attribution', item)"></span>
        <span>
          {{ $t('pageMadeBy') }}
          <a
            :href="`https://www.kvass.no?referrer=${currentUrl}`"
            target="_blank"
            style="font-weight: bold"
          >
            Kvass
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'



export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness

      if (!this.item.customFields) return

      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (this.item.customFields['footer-logo']) return this.item.customFields['footer-logo'].url
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted

      return API.logo
    },
    currentUrl() {
      return encodeURIComponent(window.location.href)
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },

  components: {
    ActionComponent,
    FooterContent,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--secondary);
  color: var(--secondary-contrast);

  &__content {
    padding-block: 4rem;
  }

  &__bottom {
    padding-block: 2rem;
  }

  &__logo {
    @include respond-above('tablet') {
      grid-column: span 5;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__custom-content {
    margin-bottom: 4rem;
    a {
      text-decoration: underline;
    }
  }

  &__title {
    display: block;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }
}
</style>
