<template>
  <div class="hero" v-if="items.length">
    <Slider :value="items" :display-thumbnail="true" :manual-navigation="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'

export default {
  props: {
    description: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
    hideStats: Boolean,
  },
  computed: {
    ...mapState('Root', ['item']),
    items() {
      return this.images
    },
    keyInfo() {
      return this.$path('item.customFields.key-info') || []
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    onScroll() {
      requestAnimationFrame(() => {
        const scrollTop = window.scrollY / 4
        document.body.style.setProperty('--parallax-top', `${scrollTop}px`)
      })
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.hero {
  $height: 70vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  iframe {
    min-height: $height;
  }

  @include respond-below('tablet') {
    height: 70vh;
    iframe: {
      min-height: 70vh;
    }
  }

  @include respond-below('phone') {
    height: 29vh;
    iframe: {
      min-height: 29vh;
    }
  }

  .kvass-media-render-slider > * {
    height: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
